<template>
  <div 
    class="c-index-shop j-config-index transition-page"
    :style="wrapStyle"
  >
    <!-- top tab -->
    <HomeToptab
      ref="HomeToptabRef"
      :channel-id="channelId"
      :tab-list="topTabList"
      :show-block="commonHeaderBg.showBlock"
      :show-immersive-banner="showImmersiveBanner"
      @switch-tab="switchTabHandle"
    />
    <!-- home-components -->
    <div
      :class="{ 'index-content-container': topTabList && topTabList.length > 1 }"
      :style="indexContainerStyle"
    >
      <template
        v-for="(item) in list"
      >
        <!-- 非首页预览可能存在没有channelId -->
        <AppBff
          v-show="Number(item.channelId) === Number(channelId) || isPreview"
          ref="newCccAppReference"
          :key="`new-${item.channelId}`"
          :app-content="item"
          :active-channel-id="channelId"
          :hooks="cccAppHooks"
          :traceId="item.traceId"
          :style="getGradientStyle(item)"
        />
        <!-- ccc推荐组件 -->
        <client-only :key="`ccc-recommend-${item.channelId}`">
          <InformationFlowCcc
            v-if="isShowInformationFlow(item)"
            v-show="Number(item.channelId) === Number(channelId)"
            :key="`ccc-information-flow-${item.channelId}`"
            :is-show="Number(item.channelId) === Number(channelId)"
            :context="context"
            :nowChannelIdKey="item.channelId"
            :index="1"
          />
        </client-only>
      </template>

      <!-- loading -->
      <div
        v-if="status.loading"
        class="home-index__loading"
      >
        <div
          v-for="i in 5"
          :key="i"
          class="home-index__holder"
        >
          <s-skeleton animated>
            <template slot="template">
              <s-skeleton-item 
                variant="rect" 
                style="width: 10rem; padding: 20%;border-radius:0;" 
              />
            </template>
          </s-skeleton>
        </div>
      </div>
    </div>
    
    <!-- footer -->
    <client-only>
      <!-- 内容显示了当前元素后才开始渲染footer组件 -->
      <div class="j-foonter-expose-flag"></div>
      <div 
        class="c-index-copyright-container"
      >
        <footer-info
          class="c-index-copyright-container__shein-info"
          :title-required="true"
          :channel-id="channelId"
          arrow="content"
          data-type="home_bottom"
          :analysis-info="{ eventCategory: 'Home', exposeCode: 'home' }"
        />
      </div>

      <!-- 首页悬浮角标 新cccx -->
      <floating-icon
        :channel-name="context?.channelName"
        :scroll-dist-abt="floatingIconScrollDistAbt"
      />
    </client-only>
    <!-- 底部悬浮组件 --> 

    <template v-for="item in list">
      <bff-bottom-suspension-icon
        v-if="(
          item?.hoverComponentResponse?.[0]
          && Number(item.channelId) === Number(channelId)
        )"
        :key="`bottom-suspension-${item.channelId}`"
        :is-visible="Number(item.channelId) === Number(channelId)"
        :app-content="item"
        :prop-data="item?.hoverComponentResponse?.[0] || {}"
      />
    </template>
    <client-only>
      <HomePopover
        :channel-id="channelId"
      />
    </client-only>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { Skeleton as SSkeleton, SkeletonItem as SSkeletonItem } from '@shein/sui-mobile'
import { getQueryString, transformImg } from '@shein/common-function'
import { commonComponents } from './common/comMapping'
import { generateHighFrequencyComponents } from 'public/src/pages/components/ccc/common/comp-bff'
import { compMapping } from 'public/src/pages/components/ccc/common/comp-bff-map'
import AppBff from './AppBff.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { transfromAndCutImg } from 'public/src/services/resource/index'
import { handleEndCollectData } from 'public/src/pages/common/userActionTracker/core/index.js'
daEventCenter.addSubscriber({ modulecode: '2-22' })

import layoutAnalysis from './analysis'
import { cccxEventBus, hexToRGBA, handleColor } from 'public/src/pages/components/ccc/common/utils.js'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { metricPageSuccess } from 'public/src/pages/common/business-monitor/common.js'
import { BFooterSheinInfo as FooterInfo } from '@shein-aidc/bs-footer-shein-info-mobile-vue2'
import { windowLinstnerScrollEventInstance } from 'public/src/services/expose/index.js'

const calcTabHeaderHeight = (topTabList = []) => {
  const headerHeight = 1.17
  const tabHeight = topTabList?.length > 1 ? 1.17 : 0
  // header头部 + tab高度
  const topBgHeight = headerHeight + tabHeight
  // header头部
  const topHeaderHeight = headerHeight
  // tab高度
  const topTabHeight = tabHeight

  return {
    topTabHeight,
    topBgHeight,
    topHeaderHeight
  }
}

let timerCounter = 20

let hasPrefetchHomeChunks = false

let bodyEle = null
export default {
  name: 'HomePageEntry',
  components: {
    HomePopover: () => import('./components/HomePopover.vue'),
    ...commonComponents,
    AppBff,
    SSkeleton,
    SSkeletonItem,
    FooterInfo
  },
  provide() {
    return {
      status: this.status,
      cutImg: this.cutImg,
      homeProductJump: 'list', // 首页商品位跳列表
      metricsConfig: { // 监控配置
        reportMetrics: {
          cccClick: true,
        },
        pageName: 'page_home',
      },
    }
  },
  // SSR CSR 调用 将数据注入仓库
  async asyncData ({ store, context }) {
    // ssr或者客户端数据直出直接赋值
    if (context && context.ssrPageType === 'config_index') {
      store.state['config_index'].context = context
      if(context?.content?.channelId && context.content) {
        const channelId = context.content.channelId
        const traceId = context._traceId || ''
        store.commit('config_index/assignList', { channelId, content: context.content, traceId })
        try {
          // 防止不是 Set 数据
          store.state['config_index'].hasRequestChannelIds.add(channelId)
        } catch (error) {
          console.log(error)
        }
      }
      // 设置背景颜色 (背景图)
      const whiteColorRegExp = /^#([fF]{3}|[fF]{6})$/ // 白色
      const { backgroundImgSrc, backgroundColor: sourceBgColor, topTextColor } = context?.content?.extension || {}
      const backgroundColor = sourceBgColor ? hexToRGBA(handleColor(sourceBgColor)) : ''
      const bgImgSrc = backgroundImgSrc?.src ?? '' 
      const setHomeSkin = Boolean(bgImgSrc || backgroundColor || !whiteColorRegExp.test(sourceBgColor) || !whiteColorRegExp.test(topTextColor))
      
      store.commit('changeHomeImmersiveInfo', {
        showImmersiveBanner: context.showImmersiveBanner,
        commonHeight: calcTabHeaderHeight(context.topTabList),
        commonHeaderBg: {
          showBlock: context.showImmersiveBanner || setHomeSkin,
          homeTabStatus: context.showImmersiveBanner || setHomeSkin,
          immersiveFlag: context.showImmersiveBanner, // 沉浸式组件是否是沉浸式状态（顶部沉浸式，向下浏览非沉浸式）
          topTextColor: context.showImmersiveBanner ? '#FFFFFF' : topTextColor,
          stickyTop: true,
          backgroundColor,
          bgImgSrc: context.showImmersiveBanner ? '' : bgImgSrc,
        }
      })
      return Promise.resolve(true)
    } else {
      try {
        if (typeof window === 'undefined') throw new Error('can\'t found context for ssr')
        // 客户端渲染
        // 如果是预览
        const key = getQueryString({ key: 'contentId' }) ? 'channelId' : 'activeChannelId'
        // 首页数据 
        // NOTE: await 会在路由切换的时候等待数据返回再进入页面展示
        const homepageContext = await store.dispatch('config_index/fetchHomePageBffData', {
          activeChannelId: getQueryString({ key }),
          contentId: getQueryString({ key: 'contentId' }),
        })
        // NOTE: 可以判断设置的前提条件
        // 设置背景高度
        store.commit('changeHomeImmersiveInfo', {
          commonHeight: calcTabHeaderHeight(homepageContext?.topTabList),
        })
        return Promise.resolve(true)
      } catch (error) {
        console.log(error)
      }
    }
  },
  data() {
    return {
      status: {
        stickyTop: true, // 是否吸顶
        loading: false,
        isFromKeepAliveCache: false, // 判断进入首页是首次进入还是 keep-alive 进入
      },
      cccTabShow: {},
      userCrowdId: '',
      timer: null,
      clickCompInfo: {}, // 被点击组件信息
      csrTraceId: '',
    }
  },
  computed: {
    ...mapState(['redpoint', 'commonHeaderBg', 'commonHeight']),
    ...mapState('config_index', ['context', 'list']),
    ...mapGetters('config_index', ['content', 'homeAllBff', 'countryId', 'channelId', 'topTabList', 'topTabAbtData', 'hasPolicyBanner', 'policyBannerPlaceholder', 'freeShipping']),
    wrapStyle(){
      const styleObj = { backgroundColor: (this.commonHeaderBg?.backgroundColor) || '#fff' }
      const isHomeR56  = this.context?.content?.homePageExtra?.webDynamicAbt?.HomePictureProcess === 'Home_picture'
      if(isHomeR56){
        /* 首页商卡组件图裁剪样式 TR-37950 */
        styleObj['--crop-img-fit'] = 'cover'
      }
      return styleObj
    },
    isPreview() {
      return this.context?.isPreview || false
    },
    indexContainerStyle() {
      const style = {
        position: 'relative',
      }
      if(this.commonHeaderBg?.bgImgSrc){
        Object.assign(style,  { backgroundImage: `url(${ this.commonHeaderBg?.bgImgSrc })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: `center ${-this.commonHeight?.topBgHeight}rem`,
          backgroundSize: '10rem auto' },)
      }
      return style
    },
    showImmersiveBanner(){
      return this.list?.[this.channelId]?.content?.[0]?.styleType === 'IMMERSIVE_BANNER'
    },
    freeShippingCompInfo() {
      const content = this.list?.[this.channelId]?.content || []
      let hasFreeShipping = false
      let freeShippingIndex = -1
      let isNewUserStyle = false // 是否是免邮新客样式
      content.forEach((item, index)=> {
        if (item.styleType === 'FREE_SHIPPING_COMPONENT') {
          hasFreeShipping = true
          freeShippingIndex = index
          isNewUserStyle = item.props?.metaData?.isNewUserStyle
          return true
        }
      })
      
      return { 
        hasFreeShipping, 
        freeShippingIndex,
        height: isNewUserStyle ? 0.8533 : 0.96 // rem
      }
    },
    BackgroundGradient () {
      return this.context?.content?.homePageExtra?.webDynamicAbt?.BackgroundGradient || ''
    },
    // 当前频道的背景配置
    activeChannelExtension() {
      return this.list?.[this.channelId]?.extension
    },

    cccAppHooks() {
      return {
        IMMERSIVEBANNER: {
          setCommonHeaderStyles: ({ commonHeaderBg = null, commonHeight = null }) => {
            const params = {}
            if (commonHeaderBg) {
              params.commonHeaderBg = commonHeaderBg
            }
            if (commonHeight) {
              params.commonHeight = commonHeight
            }
            this.changeHomeImmersiveInfo(JSON.parse(JSON.stringify(params)))
          },
          calcTopHeight: () => {
            return calcTabHeaderHeight(this.topTabList)
          }
        },
      }
    },

    floatingIconScrollDistAbt() {
      return this.context?.content?.homePageExtra?.webDynamicAbt?.floatingicon_sbc_priority_x || ''
    }
  },
  watch: {
    showImmersiveBanner(val){
      this.changeHomeImmersiveInfo({ showImmersiveBanner: val })
    },
    'status.stickyTop'(stickyTop) {
      if (bodyEle && bodyEle.classList.contains('S-popup-parent__hidden')) {
        // 弹窗触发的滚动
        return
      }
      this.showImmersiveBanner && this.setAppHeaderBgColor(stickyTop)
    },
  },
  beforeRouteEnter(to, from, next) {
    // 从专题点击logo跳转首页处理
    if (typeof window !== 'undefined' && from.name === 'campaigns' && window.appRouteExtData?.direction === 0 && !window.JOURNEY_BRANCH?.close) {
      window.scrollTo(0, 0)
    }
    if (typeof window !== 'undefined' && window.resourceSdkCase) {
      window.resourceSdkCase.updateCutScene('home_page')
    }
    
    next(vm => {
            
      let channelId = ''
      let channelName = ''
      let channelInfo = {}
      // 首页新路由
      if (to.query?.activeChannelId) {
        channelId = Number(to.query?.activeChannelId)
        channelInfo = vm.topTabList?.find(i => i.channelId === channelId)
        channelName = vm.topTabList?.find(i => i.channelId === channelId)?.channelName
      } else { // 旧路由
        for (const item of vm.topTabList) {
          const link = item.url
          if (link && link.endsWith(to.path)) {
            channelName = item.channelName
            channelId = item.channelId
            break
          }
        }
      }
      // 如果没有匹配到数据默认用第一个tab的数据
      channelId = channelId || vm.topTabList?.[0]?.channelId || ''
      channelName = channelName || vm.topTabList?.[0]?.channelName || ''
      
      vm.refreshHomeComp(from, channelId, channelName, channelInfo)
    })
  },
  beforeRouteLeave (to, from, next) {
    // 重排： 跳转分类页
    from.meta.savedPosition = { x: 0, y: window?.scrollY || 0 }
    if (typeof window !== 'undefined' && window.resourceSdkCase) {
      window.resourceSdkCase.updateCutScene('')
    }
    requestAnimationFrame(() => {
      // 监听首页组件点击(只有点击跳离首页的组件才记录, 比如点击是弹出弹窗，则不记录)
      const isNotRecord = JSON.parse(sessionStorage.getItem('isNotRecord')) || false
      sessionStorage.removeItem('isNotRecord') // 只消费一次
      if (isNotRecord) return
      const { compIndex, styleType } = this.clickCompInfo
      if (!styleType) return
      sessionStorage.setItem('clickComp', JSON.stringify({ compIndex, compId: `comp-${compIndex}-${styleType}-${this.channelId}`, styleType }))
      // 被点击组件存储到 sessionStorage 后，重置状态
      this.clickCompInfo = {}
    })
    // 首次跳转购物车时，deactivated周期滞后，吸顶免邮组件隐藏未及时
    if (typeof window !== 'undefined' && to.name === 'cart') {
      this.noticeToHideFreeShipping()
    }
    next()
  },
  created() {
    // 更新资源sdk信息
    this.resetResourceSDK()
  },
  mounted() {
    bodyEle = document.body
    this.setMonitor('page_home_all', this.content, {
      render_type: 'ssr',
      channel_id: this.context?.channelId,
      channel_name: this.context?.channelName,
      lang: this.context?.lang,
      original_url: this.context?.originalUrl,
      traceId: this.context?._traceId
    })
     
    // 从券包登陆成功需刷新数据
    cccxEventBus.on?.(
      'dialog-loginSuccess', 
      () => {
        this.refreshHomeData()
      },
    )
    cccxEventBus.on?.('click-comp', (comp) => {
      this.clickCompInfo = comp
    })
    const { ssrPageType, channelName } = window.gbCommonInfo?.contextForSSR || {}
    // 首页首频道刷新清空已收集的用户行为序列 (信息流)
    if (ssrPageType  === 'config_index' && channelName === 'all') {
      handleEndCollectData('information_flow', { keep: true })
    }

    try {
      // 首页预请求下一个频道数据 
      this.prefetchNextChannelData(this.channelId)
      // 在首页预请求用户即将访问的其他页面的 js 资源
      this.prefetchHighFrequencyHomeChunks()
      // 在首页预请求用户即将访问的其他页面的 js 资源
      this.prefetchResources()
    } catch(e) {
      console.log(e)
    }
  },
  activated() {
    this.compScrollToTop()
    // 人群id = 原本有的 ｜｜ 链接中携带 ｜｜ 实验中携带
    this.userCrowdId = this.userCrowdId ?
      this.userCrowdId :
      this.$route?.query?.userCrowdId || this.context.crowdId

    // pv 埋点发送
    this.refreshAnalysis()
    this.homePageScrollEvent()

    this.listenId = windowLinstnerScrollEventInstance.listen(this.homePageScrollEvent)
    if (typeof window !== 'undefined') {
      // 获取目标元素
      const targetElement = document?.querySelector('.j-index-footer .j-index-tab-list-home')
      if (targetElement) {
        // 给目标元素添加 'title-active' 类
        targetElement.classList.add('title-active')
        // 获取目标元素的所有同级元素
        const siblingElements = Array.from(targetElement?.parentNode?.children || []).filter(child => child !== targetElement)
        // 从所有同级元素中移除 'title-active' 类
        siblingElements.forEach(element => element.classList.remove('title-active'))
      }
    }
    try {
      this.$store?.commit?.('changeSeo', this.context.pageListInfo)
    } catch(e) {
      console.log(e)
    }
    // 首次落地 & ssr 是首页
    // 首次落地与 asyncData 处理重复，增加判断减少逻辑操作
    const isFirstLandingHome = !this.status.isFromKeepAliveCache && window.gbCommonInfo.ENTRY_PAGE_NAME === 'page_home'
    if (!isFirstLandingHome) { // 非首次落地 || 切底部 tab xhr 进入
      this.showImmersiveBanner ? this.setAppHeaderBgColor(this.status.stickyTop) : this.handleIndexBgInfo(this.activeChannelExtension)
    }
    this.noticeToHideFreeShipping()
  },
  deactivated() {
    this.status.isFromKeepAliveCache = true
    // 全局状态改变，延迟执行，切换时让页面先渲染
    this.$nextTick(() => {
      this.setAppHeaderBgColor(false,  { isRouteLeave: true })
    })
    this.listenId && windowLinstnerScrollEventInstance.unListen(this.listenId)
    this.noticeToHideFreeShipping()
    this.removeHomeRefreshId()
  },
  destroyed () {
    this.$store?.unregisterModule?.('config_index')
    cccxEventBus?.off?.('register-sticky-shipping')
    cccxEventBus?.off?.('dialog-loginSuccess')
    cccxEventBus?.off?.(`freeShippingPage-will-hidden_${this.channelId}`)
    this.setAppHeaderBgColor(false, { isRouteLeave: true })
    this.timer && clearTimeout(this.timer)
  },
  methods: {
    ...mapActions('config_index', ['refreshHomeComponent', 'fetchHomeChannelData', 'prefetchNextChannelData']),
    ...mapMutations('config_index', ['assignList']),
    ...mapMutations(['changeHomeImmersiveInfo', 'changeRootStatus', 'assignRootState']),
    homePageScrollEvent() {
      // 绑定事件就会执行一次
      cccxEventBus.emit('page-scroll')
      
      const scrollThreshold = !window.JOURNEY_BRANCH.hasTopBranch ? 4 : (window.JOURNEY_BRANCH.offsetHeight ?? 76)
      let stickyTop = window.scrollY < scrollThreshold
      this.status.stickyTop = (window.JOURNEY_BRANCH.hasTopBranch === undefined && stickyTop) ? true : stickyTop
    },

    /**
     * 沉浸式背景信息处理
     * @param stickyTop 
     * @param isHomeSkin 是否是首页皮肤设置 
     * @param isRouteLeave 是否是路由离开场景
     */
    setAppHeaderBgColor(stickyTop, { isHomeSkin = false, isRouteLeave = false } = {}) {
      // 设置背景颜色 (背景图)
      const { backgroundImgSrc, backgroundColor: sourceBgColor, topTextColor } = this.activeChannelExtension || {}
      const backgroundColor = sourceBgColor ? hexToRGBA(handleColor(sourceBgColor)) : ''
      const bgImgSrc = backgroundImgSrc?.src ?? '' 
      if (stickyTop) {
        // 吸顶
        this.changeHomeImmersiveInfo({
          commonHeaderBg: {
            showBlock: true,
            homeTabStatus: true,
            immersiveFlag: !isHomeSkin, // 告诉公共组件当前沉浸式是否展示
            backgroundColor,
            stickyTop,
            ...isHomeSkin ? 
              {
                topTextColor,
                bgImgSrc,
              } : 
              {
                topTextColor: '#ffffff', // 目前可写死 自动聚合、清晰 => #ffffff
                bgImgSrc: '',
              }
          }
        })
      } else {
        // 头部下滑是否展示首页背景
        const headerShowHomePageBg = this.context?.content?.homePageExtra?.webDynamicAbt?.HomePageToppingBG == 'background'
        // 非吸顶
        this.changeHomeImmersiveInfo({
          commonHeaderBg:
            !isHomeSkin && !isRouteLeave ? 
              {
                stickyTop,
                backgroundColor,
                ...headerShowHomePageBg ? 
                  {
                    topTextColor: this.activeChannelExtension?.topTextColor, // 传入自定义颜色
                    bgImgSrc: bgImgSrc,
                    showBlock: Boolean(bgImgSrc),
                    homeTabStatus: Boolean(bgImgSrc),
                  } : {
                    showBlock: false,
                    homeTabStatus: false,
                  },
              } : 
              {
                stickyTop,
                showBlock: false,
                homeTabStatus: false,
              }
        })
      }
    },

    removeSessionStorage() {
      sessionStorage.removeItem('clickComp')
      sessionStorage.removeItem('multiLineRowId')
      sessionStorage.removeItem('information-flow-touched-class')
    },
    /**
     * NOTE: 当前的数据能否通过组件内部通知？
     */
    handleFreeShippingComp(informationFlowClassName, lastClickComp, htmlFontSize) {
      let freeShippingDom = null
      let freeShippingHeight = 0
      // 信息流处理
      if (informationFlowClassName) {
        if (this.freeShippingCompInfo.hasFreeShipping) {
          freeShippingHeight = this.freeShippingCompInfo.height * htmlFontSize
        } else {
          freeShippingHeight = 0
        }

        return freeShippingHeight
      }
      // 当免邮组件存在的时候，需要考虑免邮组件吸顶后的高度(0.96rem)
      if (this.freeShippingCompInfo.hasFreeShipping) {
        freeShippingDom = document.getElementById(`comp-${this.freeShippingCompInfo.freeShippingIndex}-FREE_SHIPPING_COMPONENT-${this.channelId}`)
        // 找到免邮组件后面的第一个有高度的组件(即展示的组件)
        let nextElement = freeShippingDom.nextElementSibling
        while (nextElement && nextElement.offsetHeight === 0) {
          nextElement = nextElement.nextElementSibling
        }

        const nextElementId = nextElement?.id
       
        if ((lastClickComp.compIndex > this.freeShippingCompInfo.freeShippingIndex) && nextElementId !== lastClickComp.compId) {
          freeShippingHeight = this.freeShippingCompInfo.height * htmlFontSize
        } else if ((lastClickComp.compIndex > this.freeShippingCompInfo.freeShippingIndex) && nextElementId === lastClickComp.compId) { // 点击的是免邮组件后面的第一个组件
          // 排在免邮组件后面的第一个组件不需要考虑免邮组件的高度
          freeShippingHeight = 5 // 留出一点距离，否则会被免邮组件遮挡
        } else {
          freeShippingHeight = 0
        }
      }

      return freeShippingHeight
    },
    // 处理不支持平滑移动(scrollBehavior)的浏览器
    smoothScrollTo(targetPosition, duration = 300) {
      const startPosition = window.pageYOffset
      const distance = targetPosition - startPosition
      let startTime = null

      function animation(currentTime) {
        if (startTime === null) startTime = currentTime
        const timeElapsed = currentTime - startTime
        const run = easeInOutQuad(timeElapsed, startPosition, distance, duration)
        window.scrollTo(0, run, timeElapsed, duration)
        
        if (timeElapsed < duration) {
          requestAnimationFrame(animation)
        }
      }

      // 缓动函数
      function easeInOutQuad(t, b, c, d) {
        t /= d / 2
        if (t < 1) return c / 2 * t * t + b
        t--
        return -c / 2 * (t * ( t - 2) - 1) + b
      }

      requestAnimationFrame(animation)
    },
    compScrollToTop() {
      // 只消费一次
      const lastClickComp = JSON.parse(sessionStorage.getItem('clickComp')) || {} // 首页组件点击记录
      const informationFlowClassName = sessionStorage.getItem('information-flow-touched-class') || '' // 首页信息流点击记录
     
      if (!lastClickComp.compId && !informationFlowClassName) return
      const clickDom = document.getElementById(lastClickComp.compId) || (informationFlowClassName && document.querySelector(`.${informationFlowClassName}`))
     
      // 从落地页刷新回到首页，首页走的是客户端渲染，这种情况需要等到元素已经渲染完毕再滚动(否则高度计算有问题)
      if (clickDom?.offsetHeight === 0 && timerCounter > 0) {
        this.timer = setTimeout(() => {
          this.compScrollToTop()
        }, 500)
        timerCounter-- // 最多20次，避免意外情况导致死循环
        return
      }
      sessionStorage.removeItem('clickComp')
      sessionStorage.removeItem('information-flow-touched-class')

      const styleType = lastClickComp.styleType

      const htmlFontSize = parseFloat(getComputedStyle(document.documentElement)?.fontSize) ?? 0
      // header + tab 的高度为 2.34rem
      const height = 2.34 * htmlFontSize
     
      this.$nextTick(() => {
        let compDom = null
        if (styleType === 'MULTI_LINE_CONTAINER_COMPONENT') { // 灵活布局组件有多行，应该取点击的那一行对应的组件
          const multiLineRowId = sessionStorage.getItem('multiLineRowId')
          compDom = document.getElementById(multiLineRowId)
          sessionStorage.removeItem('multiLineRowId')
        } else if (informationFlowClassName) {
          compDom = document.querySelector(`.${informationFlowClassName}`)
        } else {
          compDom = document.getElementById(lastClickComp.compId)
        }
      
        if (!compDom) return

        const freeShippingHeight = this.handleFreeShippingComp(informationFlowClassName, lastClickComp, htmlFontSize)
        // 被点击组件距离视口顶部的距离
        const topToViewPort = compDom.getBoundingClientRect().top
        // 被点击组件在页面中的实际位置
        const compDomDistance = topToViewPort + (window.scrollY || document.documentElement.scrollTop)
      
        if (topToViewPort < (height + freeShippingHeight)) return // 如果被点击组件在header + tab + 免邮吸顶 的高度范围内,则其已被遮住,不需要滚动
        
        // 浏览器是否支持平滑滚动
        const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style
        // 不支持平滑滚动则使用自定义平滑滚动
        if (!supportsNativeSmoothScroll) {
          setTimeout(() => {
            this.smoothScrollTo(compDomDistance - height - freeShippingHeight)
          }, 50)
          return
        }

        setTimeout(() => {
          window.scrollTo({
            top: compDomDistance - height - freeShippingHeight,
            behavior: 'smooth'
          })
        }, 50) 
      })
    },
    handleIndexBgInfo(contentExtension){
      try {
        if (!contentExtension) return
        const whiteColorRegExp = /^#([fF]{3}|[fF]{6})$/
        const extension = typeof contentExtension == 'object' ? contentExtension : JSON.parse(contentExtension ?? '{}')
        // 计算背景图相关信息
        const imgSrc = extension?.backgroundImgSrc?.src && transformImg( { img: extension?.backgroundImgSrc?.src })
        const backgroundColor = extension?.backgroundColor
        const topTextColor = extension?.topTextColor
        // 1. 无背景色 且 无背景颜色 => return不生效沉浸式
        // 或者2. 无背景图 且 页面底色配了白色 且 文本颜色配了白色 => return不生效沉浸式
        if((!backgroundColor && !imgSrc) || (!imgSrc && whiteColorRegExp.test(backgroundColor) && whiteColorRegExp.test(topTextColor))) {
          this.setAppHeaderBgColor(false,  { isHomeSkin: true })
          return
        }
        // 1. 设置背景色
        this.setAppHeaderBgColor(true,  { isHomeSkin: true })
      } catch (e) {
        console.log(e)
      }
    },

    prefetchResources() {
      // el 必须是渲染完的
      prefetchResource.listen({
        el: this.$el,
        prefetchList: [
          // 列表
          {
            chunkName: 'plv2_container',
            relType: 'prefetch'
          },
          // 个人中心子路由容器
          {
            chunkName: 'user',
            relType: 'prefetch'
          },
          {
            chunkName: 'user-index',
            relType: 'prefetch'
          },
        ],
        prefetchCallback: ({ status, info }) => {
          console.log('prefetchCallback', status, info)
        },
        delay: 5000, // 默认两秒
        ioCallback: () => {
          // console.log('ioCallback')
          // 数据预取用
        }
      })
    },

    prefetchHighFrequencyHomeChunks() {
      // 只在bff模式下进入
      if (hasPrefetchHomeChunks) return
      const getExtraHighFrequencyChunks = () => {
        const usedComps = (Object.values(this.list)?.[0]?.content ?? []).map(n => compMapping({ styleType: n.styleType })).filter(n => !!n) ?? []
        const highFrequencyComponents = generateHighFrequencyComponents()
        const res = []
        Object.keys(highFrequencyComponents).forEach(k => {
          if (!usedComps.includes(k)) {
            res.push(highFrequencyComponents[k])
          }
        })
        return res
      }
      const prefetchList = getExtraHighFrequencyChunks().map(n => ({
        chunkName: n.chunkName,
        relType: 'prefetch',
        resolve: n.resolve
      }))
      hasPrefetchHomeChunks = true
      if (!prefetchList?.length) return
      requestIdleCallback(() => {
        prefetchResource.listen({
          el: this.$el,
          prefetchList,
          prefetchCallback: ({ status, info }) => {
            prefetchList.forEach(n => {
              const loadComponent = prefetchResource.importAsyncComponent({
                chunkName: n.chunkName,
                componentFactory: n.resolve
              })
              loadComponent?.()
            })
          },
          delay: 5000, // 默认两秒
          ioCallback: () => {
            // console.log('ioCallback')
            // 数据预取用
          }
        })
      })
    },

    // tab数据的切换
    async switchTabHandle(result) {

      // 在 Tab 切换之前，如果需要做一些事情...
      cccxEventBus.emit('home-before-tab-switch', result)

      this.removeSessionStorage()
      const { channelId, channelName } = result || {}
      this.noticeToHideFreeShipping()
      // 先清空
      if (Object.keys(this.commonHeaderBg).length) this.changeHomeImmersiveInfo({ commonHeaderBg: { showBlock: false, homeTabStatus: false } })
      // 1. 路由切换
      const url = `${this.langPath}?activeChannelId=${channelId}`
      this.$router.replace(url)
      // 3. 保存频道ID
      const { lang } = typeof window !== 'undefined' ? window.gbCommonInfo : {}
      window.sessionStorage.setItem(`${lang}-pwa-home-channel`, JSON.stringify(channelId))
      
      // 4. 请求对应频道数据
      this.fetchHomeData({ 
        channelId, 
        channelName, 
        channelInfo: result,
      })
      // 无需同步等待fetchHomeData方法完成。fetchHomeData，未完成时，当前tab已有数据，判断是否需要刷新；无数据时，当前tab是第一次进入，不刷新
      // tab切换，写死name: 'config_index'，模拟beforeRouteEnter
      this.refreshHomeComp({ name: 'config_index' }, channelId, channelName, result)
    },

    /**
     * 查询对应的频道首页的配置
     * @param clientRefresh: 客户端刷新当前频道的标识，目前只有券包登陆成功后需要刷新
     */
    async querySenceData({ channelId, channelName, channelInfo = {}, clientRefresh = false }) {
      if (!channelId) return Promise.resolve()
      // 1. 更新 vuex 中的场景值数据
      this.$store.state['config_index'].context.channelId = channelId
      this.$store.state['config_index'].context.channelName = channelName
      await this.$nextTick()
      // 2. 请求数据
      if (!this.list[channelId] || clientRefresh) {
        !clientRefresh && (this.status.loading = true)
        try {
          const res = await this.fetchHomeChannelData({
            channelId, 
            position: channelInfo?.position,
            channelName,
            clientRefresh,
          })
          this.setMonitor('page_home', res?.content || {}, {
            render_type: 'client',
            channel_id: this.context?.channelId,
            channel_name: this.context?.channelName,
            lang: this.context?.lang,
            original_url: this.context?.originalUrl,
            traceId: this.csrTraceId,
          })

          !clientRefresh && (this.status.loading = false)
         
        } catch (error) {
          this.status.loading = false
        }
      }
      // 预请求下一个频道的数据
      this.prefetchNextChannelData(channelId)
      
      // 返回一个 resolve 状态
      return Promise.resolve()
    },
    // 是否展示信息流组件
    isShowInformationFlow(item) {
      const informationFlowConfig = item?.content?.find(item =>  item.componentKey === 'INFORMATION_FLOW_OCCUPANCY')
      if (informationFlowConfig) {
        return true
      } else {
        return false
      }
    },

    /**
     * 重置页面埋点信息
     */
    refreshAnalysis () {
      this.$refs['newCccAppReference']?.length && this.$refs['newCccAppReference'].forEach(_ => _.refreshAnalysis?.())

      let index = 0
      const item = this.topTabList?.find((item, i) => {
        if (this.channelId === item.channelId) {
          index = i
          return item
        }
      })
      
      item && layoutAnalysis.refreshAnalysisParams({
        params: {
          tab_id: item.channelId,
          tab_title: item.channelName,
          tab_hole: index + 1,
          tab_crowd_id: this.userCrowdId,
          ccc_abt_type: item.channelId ? (this.list?.[item.channelId]?.abtBranch || '') : '',
        }
      })

      // 2. 发送pv
      window.appEventCenter?.$emit('pageOnload')
      window.TPM?.publish('viewhome')
    },

    resetResourceSDK () {
      if (typeof window === 'undefined') return
      const { RESOURCE_SDK } = gbCommonInfo
      if  (!this.context.RESOURCE_SDK && RESOURCE_SDK) {
        this.$store.state['config_index'].context.RESOURCE_SDK = RESOURCE_SDK
      }
    },

    /** 图片裁切 */
    cutImg (imgUrl, designWidth, exp) {
      const { RESOURCE_SDK = {} } = this.context || {}
      const { deviceData = '', isSupportWeb = '', isSupprotCut = false, sceneMap = {} } = RESOURCE_SDK || {}

      const cutData = {
        deviceData,
        isSupportWebp: Boolean(isSupportWeb),
        isSupprotCut,
        imgUrl,
        designWidth: Number(designWidth),
        sceneMap,
        // scene: 'home_page',
        exp,
      }

      return transfromAndCutImg(cutData)
    },

    // 首页背景图渐变样式
    getGradientStyle(item) {
      // 检查信息流数据是否存在
      if (!this.isShowInformationFlow(item)) {
        return ''
      }
      const bgColor = this.commonHeaderBg && this.commonHeaderBg.backgroundColor ? this.commonHeaderBg.backgroundColor : '#fff'
      const defaultGradient = `background: linear-gradient(to top, #F6F6F6 0px, ${bgColor} 152px, ${bgColor});`
      if (!this.BackgroundGradient) {
        return ''
      }
      if (this.BackgroundGradient === 'show') {
        return defaultGradient
      }
      const ids = this.BackgroundGradient.split(',').map(id => Number(id.trim()))
      if (item.channelId && ids.includes(Number(item.channelId))) {
        return defaultGradient
      }
      return ''
    },
    // 业务埋点监控
    setMonitor (page, contextData = {}, extraData = {}) {
      metricPageSuccess({ 
        page, 
        status: (contextData?.content?.length) ? '1' : '0',
      },
      undefined,
      extraData)
    },
    refreshHomeData() {
      // 刷新当前频道的数据
      const currentChannelInfo = this.topTabList?.find(i => i.channelId === this.channelId)
      this.fetchHomeData({
        channelId: this.channelId, 
        channelName: this.topTabList?.[0]?.channelName, 
        channelInfo: currentChannelInfo,
        clientRefresh: true 
      })
    },
    async fetchHomeData({ channelId, channelName, channelInfo, clientRefresh = false } = {}) {
      // 1. 获取首页数据
      await this.querySenceData({ channelId, channelName, channelInfo, clientRefresh })
      // 1. 刷新页面埋点
      this.refreshAnalysis()
      // 3. 处理背景图信息
      !this.showImmersiveBanner ? this.handleIndexBgInfo(this.activeChannelExtension) : this.setAppHeaderBgColor(this.status.stickyTop)
    },

    noticeToHideFreeShipping() {
      if (this.freeShippingCompInfo.hasFreeShipping) {
        cccxEventBus.emit(`freeShippingPage-will-hidden_${this.channelId}`)
      }
    },

    // wiki.dotfashion.cn/pages/viewpage.action?pageId=1549996282
    refreshHomeComp(from = {}, channelId, channelName, channelInfo = {}) {
      const newCccAppReference = this.$refs?.newCccAppReference || [] // ui页面中的ref数据，用于获取当前组件中是否有已被更新的数据
      this.refreshHomeComponent({ 
        from,
        channelId, 
        channelName, 
        position: channelInfo?.position || 1,
        newCccAppReference
      })
    },

    removeHomeRefreshId() {
      layoutAnalysis.removeHomeRefreshId()
    }
  },
}
</script>

<style lang="less">
@zindex-hack-negative: -1;
@zindex-zero: 0;
@zindex-hack: 1;
.c-index__bg{
   position: absolute;
   width: 100%;
   top: 0;
   z-index: @zindex-hack-negative;
  
  .c-index__bg-bottom{
    top:0;
    left: 0;
    width: 100%;
    z-index: @zindex-zero;
    position: absolute;
    background-repeat: no-repeat; /* 垂直方向无缝重复 */
    background-size: 100% auto; /* 设置背景图片宽度为100%，高度自适应 */
    background-position: 0 -88px; /* 将背景图片的起始位置设置在顶部 */
  }
}
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.c-index-shop {
  position: relative;
  padding-bottom: 50px;
  background-color: #fff;
  // &.tab-hidden {
  //   visibility: hidden;
  //   pointer-events: none;
  // }
}
.c-index-copyright-container{
  padding: 0.64rem 0.64rem 0.53rem;
  background-color: #f6f6f6;
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
  // .c-index-shein-info{
  //   border-bottom: 0.026rem solid #E5E5E5;
  //   text-align: center;
  // }
}
.index-content-container{
  min-height: 600px;
}

.j-config-index {
  .product-item__main-img {
    width: 100%;
    // height: auto;
  }
  // 黑五氛围图
  .black-friday-logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  // img {
  //   width: 100%;
  //   border: 0;
  //   // height: initial;
  //   height: 100%;
  // }
  .index-image {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    background-size: cover;
    &.index-image-4-normal {
        background-color: #fff;
        .index-image-item {
            &:last-child {
              padding-right: 0;
            }
        }
        .lazyload-irregular-ctn {
          box-shadow: initial;
        }
    }
    .index-countdown-double {
        margin: 0 .32rem;
        + .index-countdown-double {
            margin-left: 0;
        }
    }
    .index-image-item {
      position: relative;
      flex: 1;
      .item-image {
        height: 100%;
        display: block;
        img {
          display: block;
        }
      }
    }
    .index-image-item.image-with-title {
      display: inline-block;
      text-align: center;
      padding: .2rem 0;
      text-decoration: none;
      width: 25%;
      &:first-of-type {
        margin-left: .32rem;
      }
      &:last-of-type {
        margin-right: .32rem;
      }
    }
    .item-title {
      display: block;
      margin: 0 0.1067rem;
      .font-dpr(24px);
      color: #222;
      height: fit-content;
      word-wrap: break-word;
      text-align: center;
      .line-camp();
    }
    .image-icon {
      overflow: hidden;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
}

.home-index__holder {
  margin-bottom: 10px;
}

.home-index__loading {
 
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: @zindex-hack;
}
</style>
